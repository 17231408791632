<template>
	<div class="side-buttons">
		<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
			<font-awesome-icon icon="fa-light fa-phone" size="1x" />
		</a>
		<a :href="`mailto:${defaults[locale].website.email}`">
			<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
		</a>
	</div>
</template>

<script setup>
const { locale } = useI18n();

const { defaults, fetchDefaults } = useDefaults();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.side-buttons {
	position: fixed;
	left: 20px;
	top: calc(50% - 30px);
	border-radius: 30px;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	background: var(--primary-background-color);
	box-shadow: 0 24px 24px rgba(0 0 0 / 15%);
	z-index: 100;
	padding: 10px 20px;

	a {
		color: var(--cta-color);
		margin: 16px 0;
		font-size: 18px;
	}
}

@media (max-width: 880px) {
	.side-buttons {
		left: 10px;
		padding: 8px 16px;

		a {
			font-size: 16px;
		}
	}
}
</style>
